import { render, staticRenderFns } from "./saveGoodsName.vue?vue&type=template&id=03fdb4ca&scoped=true"
import script from "./saveGoodsName.vue?vue&type=script&lang=js"
export * from "./saveGoodsName.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "03fdb4ca",
  null
  
)

export default component.exports