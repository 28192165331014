<template>
    <!--新增对话框-->
    <el-dialog :title="editForm.goodsNameId ? '编辑货物名称' : '新增货物名称'" :visible.sync="dialogVisible" width="600px"
        :close-on-click-modal="false" :before-close="handleClose">
        <el-form :model="editForm" :rules="editFormRules" ref="editForm">
            <el-form-item label="货物名称" prop="goodsName" label-width="100px">
                <el-input v-model="editForm.goodsName" autocomplete="off"></el-input>
            </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button @click="resetForm('editForm')">取 消</el-button>
            <el-button type="primary" @click="submitForm('editForm')">确 定</el-button>
        </div>
    </el-dialog>
</template>

<script>
export default {
    data() {
        return {
            editForm: {},
            dialogVisible: false,
            editFormRules: {
                goodsName: [
                    { required: true, message: '请输入货物名称', trigger: 'blur' }
                ]
            },
        }
    },

    methods: {
        resetForm(formName) {
            this.$refs[formName].resetFields();
            this.dialogVisible = false
            this.editForm = {}
        },
        handleClose() {
            this.resetForm('editForm')
        },
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.editForm.sysInsurancePremium = this.editForm.insurancePremium;
                    this.$axios.post('/admin/goodsname/' + (this.editForm.goodsNameId ? 'updateGoodsName' : 'saveGoodsName'), this.editForm)
                        .then(res => {
                            this.$emit('refreshDataList')
                            this.$message({
                                showClose: true,
                                message: '恭喜你，操作成功',
                                type: 'success',
                                onClose: () => { }
                            });
                            this.dialogVisible = false
                            this.resetForm(formName)
                        })
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },

        init(goodsNameId) {
            if (goodsNameId != null && goodsNameId != '') {
                this.$axios.post('/admin/goodsname/getGoodsNameInfo', { goodsNameId: goodsNameId }).then(res => {
                    this.editForm = res.data
                })
            }
            this.dialogVisible = true;
        },

    }
}
</script>

<style scoped></style>
